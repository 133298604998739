<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-data-iterator :items="subscribeList">
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-card flat outlined>
                  <v-card-text class="card_list">
                    <ul>
                      <li>
                        <div class="myleft">offer type</div>
                        <div class="myright">
                          {{ item.subscriber_typesName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li v-show="isMedics">
                        <div class="myleft">specialty</div>
                        <div class="myright">
                          {{ item.specializationName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li v-show="isMedics">
                        <div class="myleft">specialty detail</div>
                        <div class="myright">
                          {{ item.specializationDetailName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">active</div>
                        <div class="myright">
                          {{ item.user.active ? "Yes" : "No" }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../../mixins/utils";
export default {
  props: {
    subscriber: { type: String },
  },
  mixins: [Utils],
  data: () => ({ subscribeList: [] }),
  computed: {
    isMedics: function() {
      let subtype = localStorage.getItem("user.subscriber_type");
      if (subtype > 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.findMe();
  },
  methods: {
    findMe() {
      let self = this;
      Restful.mySubscriber
        .find(this._decode(this.subscriber))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.subscribeList = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
  },
};
</script>
