<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-tabs v-model="babs" centered show-arrows>
          <v-tab v-for="i in backgroundList" :key="i.id">
            {{ i.specialization }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="babs">
          <v-tab-item v-for="i in backgroundList" :key="i.id">
            <v-row style="margin: 10px 0px">
              <v-col cols="12" sm="6">
                <v-card flat outlined>
                  <v-card-text>
                    <div class="card_list">
                      <ul>
                        <li>
                          <div class="myleft">current work</div>
                          <div class="myright">
                            {{ i.current_work }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">experience</div>
                          <div class="myright">
                            {{ i.experience }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">gained from</div>
                          <div class="myright">
                            {{ i.gained_from }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">started</div>
                          <div class="myright">
                            {{ i.started | myDate }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">ended</div>
                          <div class="myright">
                            {{ i.ended | myDate }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">showing</div>
                          <div class="myright">
                            {{ i.showing ? "Yes" : "No" }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card flat outlined>
                  <v-card-text>
                    <div class="card_list">
                      <ul>
                        <li>
                          <div class="myleft">contact</div>
                          <div class="myright">
                            {{ i.contact }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">contact phone</div>
                          <div class="myright">
                            {{ i.contact_phone }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">ontact email</div>
                          <div class="myright smallcaps">
                            {{ i.ontact_email }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">endorsed</div>
                          <div class="myright">
                            {{ i.endorsed ? "Yes" : "No" }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">updated</div>
                          <div class="myright">
                            {{ i.updated | myDating }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                        <li>
                          <div class="myleft">created</div>
                          <div class="myright">
                            {{ i.created | myDating }}
                          </div>
                          <div class="clearall"></div>
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../../mixins/utils";
export default {
  props: {
    subscriber: { type: String },
  },
  mixins: [Utils],
  data: () => ({ babs: null, backgroundList: [] }),
  created() {
    this.list();
  },
  methods: {
    list() {
      let self = this;
      Restful.sub_background
        .list(this._decode(this.subscriber))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.backgroundList = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
  },
};
</script>
