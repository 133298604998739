<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-simple-table>
          <tbody class="profile_details">
            <tr>
              <td>
                <ul>
                  <li>Business Name:</li>
                  <li>
                    {{ details.business_name }}
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Business Email:</li>
                  <li>{{ details.business_email }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>phone:</li>
                  <li>{{ details.phone }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Country:</li>
                  <li>{{ details.country }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>region/state:</li>
                  <li>{{ details.regions }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>district:</li>
                  <li>{{ details.districts }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>physical location:</li>
                  <li>{{ details.physical_address }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>city:</li>
                  <li>{{ details.city }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>postal address:</li>
                  <li>{{ details.postal_address }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>postal address:</li>
                  <li>{{ details.postal_address }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>geo location:</li>
                  <li>
                    {{ details.geolocation }}
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon color="primary" v-on="on">
                          <v-icon>mdi-earth</v-icon>
                        </v-btn>
                      </template>
                      <span>View on Google Map</span>
                    </v-tooltip>
                  </li>
                </ul>
              </td>
              <!-- <td>
                <ul>
                  <li>rating:</li>
                  <li>
                    <v-rating
                      readonly
                      background-color="#c8cdd7"
                      color="success"
                      hover
                      length="5"
                      :value="details.ratings"
                    ></v-rating>
                  </li>
                </ul>
              </td>
            </tr> -->
            </tr>

            <tr>
              <td>
                <ul>
                  <li>date created:</li>
                  <li>{{ details.created | myDating }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>last updated:</li>
                  <li>{{ details.updated | myDating }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../../mixins/utils";
export default {
  props: {
    subscriber: { type: String },
  },
  mixins: [Utils],
  data: () => ({ details: {} }),
  created() {
    this.findMe();
  },
  methods: {
    findMe() {
      let self = this;
      Restful.subscribers
        .find(this._decode(this.subscriber))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.details = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
  },
};
</script>
