<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-breadcrumbs style="padding: 14px 20px">
        <v-breadcrumbs-item :to="{ name: 'admin.dash' }">
          Dashboard
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item class="capitalize" :to="{ name: returnName() }">
          {{ subType() }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item class="capitalize">
          {{ _decode(fname) | capitalize }} {{ _decode(lname) | capitalize }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </v-card>
    <v-container>
      <v-row>
        <v-col cols="12" lg="3">
          <div class="spaces">
            <v-card
              :loading="loading"
              loader-height="2"
              flat
              class="spaces_all"
            >
              <v-card-text class="profile">
                <div class="d-flex justify-center mb-6">
                  <v-avatar height="180" width="150">
                    <v-img
                      :src="myPix(myAccount.my_pix)"
                      :alt="myName(fname, lname)"
                    ></v-img>
                  </v-avatar>
                </div>
                <div class="d-flex justify-center mb-6 name">
                  {{ myAccount.salutations | capitalize }}
                  {{ myAccount.first_name | capitalize }}
                  {{ myAccount.last_name | capitalize }}
                </div>
                <div class="seen">
                  <p class="num">10</p>
                  <p class="cap">Clients</p>
                </div>
                <v-toolbar flat dense>
                  <v-spacer></v-spacer>
                  <v-rating
                    style="height: 70px"
                    dense
                    color="success"
                    :value="subscriber.ratings"
                  ></v-rating>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-card-text>
              <v-toolbar flat dense>
                <v-spacer></v-spacer>
                <v-btn color="primary" text outlined rounded>
                  Email
                  <v-icon right dark> mdi-email </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-toolbar flat dense>
                <v-spacer></v-spacer>
                <v-btn color="primary" text outlined rounded>
                  {{ myAccount.mobile_phone }}
                  <v-icon right dark> mdi-phone </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-toolbar>
              <div class="remove_user">
                <v-btn
                  color="red"
                  dark
                  small
                  @click.stop="$set(removeAcDialog, _decode(id), true)"
                >
                  remove account {{ _decode(id) }}
                </v-btn>
                <UserAccountRemoval
                  v-if="removeAcDialog[_decode(id)]"
                  :myId="_decode(id)"
                  :name="myName(fname, lname)"
                  :removeAcDialog="removeAcDialog[_decode(id)]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
                />
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" lg="9">
          <SubscriberProfile :subscriber="id" :acc="ac" />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import SubscriberProfile from "./user_profile.vue";
import UserAccountRemoval from "../../components/accounts/remove_account.vue";

export default {
  props: {
    id: { type: String, default: null },
    ac: { type: String, default: null },
    fname: { type: String, default: null },
    lname: { type: String, default: null },
  },
  components: { SubscriberProfile, UserAccountRemoval },
  mixins: [Utils],
  data() {
    return {
      removeAcDialog: {},
      loading: false,
      prevRoute: null,
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: this.subType(),
          disabled: false,
          to: { name: this.returnName() },
        },
        {
          text: "Details",
          disabled: true,
        },
      ],
      subscriber: {},
      myAccount: {},
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
      vm.subType();
      vm.returnName();
    });
  },
  created() {
    this.findSubscriber();
    this.findMe();
  },
  methods: {
    myName(first, last) {
      let f = this._decode(first);
      let l = this._decode(last);
      let full = f + " " + l;
      console.log("full name: ", full);
      return full;
    },
    findSubscriber() {
      this.loading = true;
      let self = this;
      Restful.subscribers
        .find(this._decode(this.id))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.subscriber = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    findMe() {
      let self = this;
      Restful.accounts
        .findMe(this._decode(this.ac))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.myAccount = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    myPix(px) {
      if (px === "") {
        // console.log("px: null");
        return this.dataUrl;
      } else {
        // console.log("px:", px);
        return px;
      }
    },
    subType() {
      let prev = this.prevRoute;
      // console.log("prev:", prev);
      if (prev === "admin.medics") {
        return "Medics";
      } else if (prev === "admin.pharmacy") {
        return "pharmacy";
      } else if (prev === "admin.labs") {
        return "laboratory";
      } else if (prev === "admin.delivery") {
        return "delivery";
      } else if (prev === "admin.patients") {
        return "patients";
      } else {
        return "Go back";
      }
    },
    returnName() {
      let prev = this.prevRoute;
      console.log("prev:", prev);
      if (prev === "admin.medics") {
        return "admin.medics";
      } else if (prev === "admin.pharmacy") {
        return "admin.pharmacy";
      } else if (prev === "admin.delivery") {
        return "admin.delivery";
      } else if (prev === "admin.patients") {
        return "admin.patients";
      } else {
        return "admin.dash";
      }
    },
    goback() {
      console.l("back!!!");
      window.history.length() > 1
        ? this.$router.go(-1)
        : this.$router.push("/");
    },
    closeRemove(p) {
      this.$set(this.removeAcDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log("p", p);
      console.log("subType:", this.subType());

      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .delete(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.$router.push({ name: this.returnName() });
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.$set(this.removeAcDialog, p.id, p.state);
    },
  },
};
</script>
