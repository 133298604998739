<template>
  <div class="pane_body">
    <v-tabs centered grow v-model="tabs">
      <v-tab key="accProfile">
        account
      </v-tab>
      <v-tab key="bizprofile">
        business
      </v-tab>
      <v-tab key="subscriptions">
        subscriptions
      </v-tab>
      <v-tab key="backgrounds">
        background
      </v-tab>
      <v-tab key="certificate">
        certificate
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item key="accProfile">
        <DetailAccount :account="acc" />
      </v-tab-item>
      <v-tab-item key="bizprofile">
        <DetailProfile :subscriber="subscriber" />
      </v-tab-item>
      <v-tab-item key="subscriptions">
        <DetailSubscription :subscriber="subscriber" />
      </v-tab-item>
      <v-tab-item key="backgrounds">
        <Detailbackground :subscriber="subscriber" />
      </v-tab-item>
      <v-tab-item key="certificate">
        <DetailCert :subscriber="subscriber" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DetailAccount from "./subscribers/details/account.vue";
import DetailProfile from "./subscribers/details/profile.vue";
import DetailSubscription from "./subscribers/details/subscriptions.vue";
import Detailbackground from "./subscribers/details/backgrounds.vue";
import DetailCert from "./subscribers/details/certs.vue";
import Utils from "../../mixins/utils";
export default {
  props: {
    subscriber: { type: String },
    acc: { type: String },
  },
  mixins: [Utils],
  components: {
    DetailAccount,
    DetailProfile,
    DetailSubscription,
    Detailbackground,
    DetailCert,
  },
  data: () => ({ tabs: null }),
};
</script>
