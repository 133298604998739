<template>
  <v-dialog v-model="removeAcDialog" persistent max-width="400">
    <v-card>
      <v-card-title>remove {{ name }}</v-card-title>
      <v-card-text>
        Are you sure you want to permanently remove this subscriber and all
        related activities from the system?
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    removeAcDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
    };
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeRemove", payload);
    },
    submit() {
      let payload = { id: this.myId, state: false };
      this.$emit("submitRemove", payload);
    },
  },
};
</script>
