<template>
  <div>
    <v-card outlined loader-height="1" :loading="loading">
      <v-card-text>
        <v-simple-table>
          <tbody class="profile_details">
            <tr>
              <td>
                <ul>
                  <li>full Name:</li>
                  <li>
                    {{ details.salutations | capitalize }}
                    {{ details.first_name | capitalize }}
                    {{ details.mid_name | capitalize }}
                    {{ details.last_name | capitalize }}
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>gender:</li>
                  <li>{{ details.gendersName }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>email:</li>
                  <li>{{ details.email }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>mobile phone:</li>
                  <li>{{ details.mobile_phone }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>created:</li>
                  <li>{{ details.created | myDating }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>updated:</li>
                  <li>{{ details.updated | myDating }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>active:</li>
                  <li>{{ details.active ? "Yes" : "No" }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>account reset</li>
                  <li>{{ details.my_reset ? "Yes" : "No" }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>reset timeout:</li>
                  <li>{{ details.my_timeout | myDating }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>reset code reset</li>
                  <li>{{ truncateString(details.my_reset_code, 20) }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../../mixins/utils";
export default {
  props: {
    account: { type: String },
  },
  mixins: [Utils],
  data: () => ({ loading: false, details: {} }),
  created() {
    this.findMe();
  },
  methods: {
    findMe() {
      let self = this;
      Restful.accounts
        .findMe(this._decode(this.account))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.details = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
  },
};
</script>
