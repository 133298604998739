<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-data-iterator :items="certList">
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-card flat outlined>
                  <v-img height="250" :src="pix(item.file_name)"></v-img>
                  <v-card-text class="card_list">
                    <ul>
                      <li>
                        <div class="myleft">name</div>
                        <div class="myright">
                          {{ truncateString(item.name, 22) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">description</div>
                        <div class="myright">
                          {{ truncateString(item.description, 20) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">reg. #</div>
                        <div class="myright">
                          {{ truncateString(item.registration_number, 20) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">active</div>
                        <div class="myright">
                          {{ item.active }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">aquired</div>
                        <div class="myright">
                          {{ item.aquired_since | myDate }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../../mixins/utils";
export default {
  props: {
    subscriber: { type: String },
  },
  mixins: [Utils],
  data: () => ({ certList: [] }),
  created() {
    this.list();
  },
  methods: {
    prevDisable(pix) {
      if (pix === "") {
        return true;
      } else {
        return false;
      }
    },
    pix(img) {
      if (img === "") {
        return "";
      } else {
        return img;
      }
    },
    list() {
      let self = this;
      Restful.sub_docs
        .list(this._decode(this.subscriber))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.certList = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
  },
};
</script>
